.rutgersLogo {
  max-width: 200px;
  width: 100%;
}

.fullHeight {
  height: 100vh;
}

.loginButton {
  width: 100%;
  padding: 8px 0 !important;
  margin: 3px 0 !important;
  text-transform: none !important;
  max-width: 300px;
  text-align: center;
  background: #cc0033;
  border-radius: 4px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: Inter,system-ui,sans-serif;
  font-weight: 500;
  line-height: 1.75;
  color: #fff;
}

.loginButton:hover {
  color: #fff;
  background-color: rgb(142, 0, 35);
}

.iconButton {
  position: absolute;
  top: 0; bottom: 0; right: 8px;
  margin: auto;
  width: .75em; height: .75em;
}

.simpleLink {
  text-transform: none !important;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: Inter,system-ui,sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  color: #cc0033;
  padding: 8px 12px;
}

.simpleLink:hover {
  text-decoration: underline !important;
  color: #cc0033;
  background-color: rgba(204, 0, 51, 0.04);
}
